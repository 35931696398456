@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Source+Sans+Pro:wght@300&display=swap');

.contact-section {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.contact-section a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2rem;
  text-decoration: none;
  color: #25a18e;
}
.contact-section a:hover {
  color: rgb(31, 30, 30);
}
.contact-section h1 {
  color: 1.4rem;
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
  color: #25a18e;
  margin-top: 80px;
}

.button-form-section {
display: flex;
flex-direction: row;
font-size: 1.2rem;
margin-top: -40px;
margin-bottom: 40px;
}


.call-section {
  display: flex;
  align-items: center;
  margin:40px 20px 0px 20px
}



.phone-logo {
 width:7%;
 margin:20px
}


.wa-logo {
  width: 7%;
  margin: 20px 30px;

}

.mail-logo{
  width: 7%;
  margin:20px
}

.linkedin-logo {
  width: 7%;
  margin: 20px;
}
.cv-logo {
  width: 7%;
  margin: 20px;
}



.call-button .button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  color: #363636;;
  height: 40px;
  width: 145%;
  margin: auto;
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 45%;
  background-color: #25a18e;
  border:none;
  }

.call-button .button:hover {
background-color: #00a5cf;
}

.call-button a {
  text-decoration: none;
}


.form-section {
    display: flex;
  flex-direction: column;
  margin:40px 40px 0px 20px  
}

.contact-section .resume {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.contact-section p {
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.contact-section a.btn.btn-primary {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  background-color: 1.4rem;
  color: #ced3b4;
  text-decoration: none;
  border-radius: 30px;
  height: 40px;
  width: 40%;
  margin: auto;
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-section a.btn.btn-primary:hover {
  transition: 0.2s;
  background: #ced3b4;
}

.social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.social-svg-mask .git {
  fill: rgb(46, 46, 46);
}


.cvImage{
  height: 5%;
  width: 4.5%;
  margin-left: 20px !important;
  margin-top: 50px;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
.call-button .button{
    width: 130%;
  }
}

@media screen and (min-width: 1023px) {
  .contact-section {
    display: flex;
    justify-content: center;
    align-items: left;
    margin-top: 60px;
  }

  .contact-section a {
    font-size: 1.2rem;
  }
  .contact-section h1 {
    font-size: 2.4rem;
  }
  .contact-section .resume {
    margin-top: -20px;
  }
}


@media screen and (min-width: 734px) and (max-width: 767px) {
.call-button .button{
    width: 150%;
  }
}

@media screen and (min-width: 684px) and (max-width: 733px) {
 .call-button .button{
    width: 130%;
  }
}

@media screen and (min-width: 667px) and (max-width: 683px) {
.call-button .button{
    width: 130%;
  }
}

@media screen and (min-width: 412px) and (max-width: 666px) {

.call-button .button{
    width: 105%;
  }

  .button-form-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .phone-logo {
    width:13%;
   }
   
   
   .wa-logo {
     width: 13%;
   
   }
   
   .mail-logo{
     width: 13%;
   }
   
   .linkedin-logo {
     width: 13%;
   }
   .cv-logo {
     width: 13%;
   }
}

@media screen and (min-width: 375px) and (max-width: 411px) {
.call-button .button{
    width: 125%;
  }

  .button-form-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-description{
    margin-top: -30px;
  }

  

.phone-logo {
  width:13%;
 }
 
 .wa-logo {
   width: 13%;
 }
 
 .mail-logo{
   width: 13%;
 }
 
 .linkedin-logo {
   width: 13%;
 }
 .cv-logo {
   width: 13%;
 }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .phone-logo{
    width: 12%;
  }

  .wa-logo{
    width: 12%;
  }

  .mail-logo{
    width: 12%;
  }

  .linkedin-logo{
    width: 12%;
  }

  .cv-logo{
    width: 12%;
  }
}

@media screen and (min-width: 200px) and (max-width: 319px) {
  .phone-logo{
    width: 12%;
  }

  .wa-logo{
    width: 12%;
  }

  .mail-logo{
    width: 12%;
  }

  .linkedin-logo{
    width: 12%;
  }

  .cv-logo{
    width: 12%;
  }
}