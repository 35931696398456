@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Source+Sans+Pro:wght@300&display=swap');

img {
  margin:40px
}

p {
  color: #363636;
}

li {
  color:#363636;
}

.feather {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -90px;
  margin-bottom: -60px;
}

.featherImage {
  width: 13%;
  margin: 0px;
  rotate: 80deg;
}

.featherImage1 {
  width: 13%;
  margin: 0px;
  rotate: 260deg;
  margin-top: 50px;
}

.about-section .about-hi-img .aboutImage {
  border-top-left-radius: 75% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 75% 50%;
  border-bottom-left-radius: 50% 50%;
  height: 15%;
  width: 25%;
  }

.about-section {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
}

.about-section p {
  margin-top: 20px;}


  .work-section{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.about-work {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

li {
  list-style-type:circle;
  margin: 10px;
}

 .about-hi h1 {
  font-size: 3rem;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 10px;
  color: #fdcf00;
}

.about-hi-contact h1 {
  font-size: 3rem;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 10px;
  color: #890f54;
  margin-bottom: 20px;
}

.about-hi-portfolio h1 {
  font-size: 3rem;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 10px;
  color: #890f54;
}

.about-section h1 {
  font-size: 1.8rem;
  font-family: 'Oswald', sans-serif;
}

.about-section .about-coding h1 {
  font-family: 'Oswald', sans-serif;
  margin-bottom: 5px;
  margin-top: 30px;
}

h2 {
  color: #890f54;
}
.about-section li {
  font-size: 1.4rem;  
}

.about-section .about-techs {
  display: flex;
}

.about-section .about-techs2 {
  margin-left: 20px;
}
.about-section .about-techs3 {
  margin-left: 20px;
}

.about-slogan {
  display: flex;
  justify-content: center;
  color: #3373A3;
  font-size: 1.8rem;
  margin: 40px 0px }
  
  .workImage {
    width: 30%;
    filter: drop-shadow(5px 10px 10px #fdcf00);  }

@media screen and (min-width: 1025px) {

  .about-hi-img {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }    
  

  .about-coding h1 {
    display: flex;
    justify-content: left;
    font-size: 2.4rem;
  }

  .about-section .about-hi h1 {
    margin-top: 120px;
    font-size: 6rem;
  }

 .about-hi-contact h1 {
    margin-top: 80px;
    font-size: 4rem;
  }

  .about-hi-portfolio h1 {
   
    font-size: 4rem;
  }

  .about-section .about-name p {
    margin-top: 150px;
    font-size: 1.4rem;
  }
  .about-section p {
    display: flex;
    justify-content: left;
    font-size: 1.4rem;
    margin-bottom: 0px;
  }

  .about-section .about-techs {
    display: flex;
    justify-content: left;
  }
  .about-section .about-techs li {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 914px) and (max-width: 1024px) {
  img {
    height: 20%;
    width: 20%;
    margin-top: 100px;
    margin-left: 30px;
  }

  .about-hi-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .workImage {
    margin-top: 10px;
  }
  .about-section .about-hi h1 {
    margin-top: 120px;
    font-size: 6rem;
  }

   .about-hi-contact h1 {
    margin-top: 0px;
    font-size: 3rem;
  }

  .about-hi-portfolio h1 {
    margin-top: 0px;
    font-size: 3rem;
  }

  .about-section .about-name p {
    margin-top: 160px;
    margin-left: 40px;
    font-size: 1.5rem;
  }

  .about-section p {
    font-size: 1.5rem;
  }
  .featherImage1{
    margin-bottom: 60px;
  }
 
  .about-work{
    margin-top: -40px;
   }
}

@media screen and (min-width: 912px) and (max-width: 913px) {

  .about-hi-img{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
  }

  .about-section h1 {
    font-size: 3rem;
  }
  .featherImage {
    margin-top: 60px;
}

.featherImage1{
  margin-bottom: 80x;
}

.work-section{
  margin-top: 60px;
}
.about-work{
  margin-top: 0px;
}

}

@media screen and (min-width: 822px) and (max-width: 911px) {
  img {
    height: 20%;
    width: 20%;
    margin-top: 100px;
    margin-left: 30px;
  }

  .about-hi-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .workImage {
    margin-top: 10px;
  }
  .about-section .about-hi h1 {
    margin-top: 120px;
    font-size: 6rem;
  }

   .about-hi-contact h1 {
    margin-top: 0px;
    font-size: 3rem;
  }

  .about-hi-portfolio h1 {
    margin-top: 0px;
    font-size: 3rem;
  }

  .about-section .about-name p {
    margin-top: 160px;
    margin-left: 40px;
    font-size: 1.5rem;
  }

  .about-section p {
    font-size: 1.5rem;
  }
  .featherImage1{
    margin-bottom: 40px;
  }
 
  .about-work{
    margin-top: -120px;
   }
}

@media screen and (min-width: 820px) and (max-width: 821px){
  .about-section .about-hi h1 {
    margin-top: 60px;
    font-size: 6rem;
  }

  .about-hi-img {
    display: flex;
    flex-direction: row;
  }

  .about-name{
    margin-top: 60px;
  }
  .featherImage{
    margin-top: 100px;
  }

  .featherImage1 {
    margin-bottom: 80px;
    margin-top: 100px;
}

.about-section li {
  font-size: 1rem;
}

.about-work{
  margin-top: 0px;
}

}

@media screen and (min-width: 769px) and (max-width: 819px) {
  img {
    height: 20%;
    width: 20%;
    margin-top: 100px;
    margin-left: 30px;
  }

  .about-hi-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .workImage {
    margin-top: 10px;
  }
  .about-section .about-hi h1 {
    margin-top: 120px;
    font-size: 6rem;
  }

   .about-hi-contact h1 {
    margin-top: 0px;
    font-size: 3rem;
  }

  .about-hi-portfolio h1 {
    margin-top: 0px;
    font-size: 3rem;
  }

  .about-section .about-name p {
    margin-top: 160px;
    margin-left: 40px;
    font-size: 1.5rem;
  }

  .about-section p {
    font-size: 1.5rem;
  }
  .featherImage1{
    margin-bottom: 40px;
  }
 
  .about-work{
    margin-top: -120px;
   }
}

@media screen and (min-width: 542px) and (max-width: 768px) {

  .about-section .about-hi-img .aboutImage {
    width: 30%;
  }

  img {
    height: 35%;
    width: 35%;
    margin-top: 40px;
    margin-left: 30px;
  }

  .about-hi-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .about-section .about-hi h1 {
    margin-top: 80px;
    font-size: 4rem;
  }

  .vision{
    margin-bottom: -40px;
  }

  .feather {
    margin-top: -50px;
    margin-bottom: -50px;
  }
  
  .featherImage{
    width: 20%;
  }
  
  .featherImage1{
    width: 20%;
    margin-bottom: 20px;
  }

.workImage{
  width: 40%;
  margin-top: -60px;
}
.about-hi-contact h1 {
    margin-top: 40px;
  }

  .about-hi-portfolio h1 {
    margin-top: 0px;
  }

  .about-section .about-name p {
    margin-top: 90px;
    margin-left: 40px;
  }

  .about-name p {
    font-size: 1.4rem;
    margin-top: 10px;
  }

  .about-section p {
    font-size: 1.4rem;
    margin-top: 30px;
  }

  ol {
    width: 97%;
  }

  .about-work{
    margin-right: 20px;
  }
}

@media screen and (min-width: 540px) and (max-width: 541px) {

  .about-hi-img{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }

  .about-section h1 {
    font-size: 3.2rem;
}

.about-section p {
  margin-top: 40px;
}

  .featherImage {
    margin-top: 90px;
    margin-bottom: 40px;
}

.featherImage1{
  margin-top: 80px;
  margin-bottom: 40px;
}

.about-section li {
  font-size: 1rem;
}

.about-work{
  margin-top: 0px;

}
.work-section {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.workImage {
  width: 90%;
}
}


@media screen and (min-width: 415px) and (max-width: 539px) {

  .about-section .about-hi-img .aboutImage {
    width: 30%;
  }

  img {
    height: 35%;
    width: 35%;
    margin-top: 40px;
    margin-left: 30px;
  }

  .about-hi-img {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .about-section .about-hi h1 {
    margin-top: 80px;
    font-size: 4rem;
  }

  .vision{
    margin-bottom: -40px;
  }

  .feather {
    margin-top: -50px;
    margin-bottom: -50px;
  }
  
  .featherImage{
    width: 20%;
  }
  
  .featherImage1{
    width: 20%;
    margin-bottom: 20px;
  }

.workImage{
  width: 40%;
}
.about-hi-contact h1 {
    margin-top: 40px;
  }

  .about-hi-portfolio h1 {
    margin-top: 0px;
  }

  .about-section .about-name p {
    margin-top: 90px;
    margin-left: 40px;
  }

  .about-name p {
    font-size: 1.4rem;
    margin-top: 10px;
  }

  .about-section p {
    font-size: 1.4rem;
    margin-top: 30px;
  }

  ol {
    width: 97%;
  }

  .about-work{
    margin-top: -10px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 375px) and (max-width: 414px) {
  .about-section .about-hi-img .aboutImage {
    width: 75%;
  }

  .about-section .about-hi h1 {
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 4rem;
  }

  .about-hi-contact h1 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

.feather {
  margin-top: 5px;
  margin-bottom: -10px;
}

.featherImage{
  width: 30%;
}

.featherImage1{
  width: 30%;
  margin-bottom: -20px;
}

  .about-hi-portfolio h1 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .about-section img {
    margin-top: 1px;
  }

  .about-name p {
    font-size: 1.4rem;
    margin-top: 10px;
  }

  .about-section p {
    font-size: 1.4rem;
    margin-top: 30px;
  }

  ul{
    margin:0px
  }

  ol {
    width: 97%;
  }
  .about-work{
    margin-top: -10px;
  }
  .work-section {
    display: flex;
    flex-direction: column;
  }

  .work-section{
    margin-top: 60px;
  }

  .workImage {
    width: 100%;
  }
}


@media screen and (min-width: 320px) and (max-width: 374px) {
  
.about-section h1 {
    font-size: 3.2rem;
}

.about-section .about-hi-img .aboutImage {
  width: 55%;
}

.feather {
  margin-top: 0px;
  margin-bottom: -20px;
}
.featherImage{
  width: 20%;
}

.featherImage1 {
  width: 20%;
    margin-top: 0px;
    margin-bottom: 40px;
} 

.work-section{
  flex-direction: column;
}

.workImage{
  width: 50%;
  margin: 0px;
}

.about-work{
  margin-top: 20px;
}

.about-section li {
  font-size: 1rem;
}

.about-slogan{
  font-size: 1.4rem;
  margin: 30px 0px;
}
}

@media screen and (min-width: 280px) and (max-width: 319px) {
  .about-section h1 {
    font-size: 3rem;

}

  .about-section .about-hi-img .aboutImage {
      width: 55%;
}

.about-section li {
  font-size: 1rem;
}

  .featherImage {
    margin-top: 90px;
    margin-bottom: 40px;
    width: 20%;
}

.featherImage1 {
  width: 20%;
  margin-top: 90px;
  margin-bottom: 90px;
}

.work-section {
  display: flex;
  flex-direction: column;
}

.workImage{
  width: 80%;
  margin: 0px;
}
}