@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Source+Sans+Pro:wght@300&display=swap');

.portfolio-section {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  margin-right: 20px;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
}

.portfolio-section p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
}

.wordpress {
  margin-top: 40px;
  margin-bottom: 10px;
}

.card {
  width:30% ;
  height: 25rem;
  margin-right: 20px;
}

.card3 {
  width:30%;
  margin-right: 20px;
}

.card2 {
  width:30% ;
}

.card-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  margin-left: 10px;
  margin-top: 10px;
  color:#363636;
}

.card-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  margin-left: 10px;
}

.portfolio-section img {
  margin: 0px 0px 0px 0px;
  height: 50%;
  width: 100%;
}

a.btn.btn-primary {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  color: #fdcf00;
  text-decoration: none;
  height: 40px;
  width: 40%;
  margin: auto;
  margin-bottom: 20px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5%;
}

a.btn.btn-primary:hover {
  transition: 0.2s;
  background: #2c5606;
}

.sissy-button.btn.btn-primary {
  background-color: #25a18e;
}
.sissy-button.btn.btn-primary:hover {
  background: #00a5cf;
}

.groenhartig-button.btn.btn-primary {
  background-color: #00a5cf;
}

.groenhartig-button.btn.btn-primary:hover {
  background-color: #25a18e;;
}

.emotus-button.btn.btn-primary {
  background-color: #25a18e;
}

.emotus-button.btn.btn-primary:hover {
  background-color: #00a5cf;
}

.birthday-button.btn.btn-primary {
  background-color: #00a5cf;
}

.birthday-button.btn.btn-primary:hover {
  background-color: #25a18e;
}

.research-button.btn.btn-primary {
  background-color: #d5a129;
}

.eva-button.btn.btn-primary {
  background-color: #fdcf00;
  color: #890f54;
}

.eva-button.btn.btn-primary:hover {
  background-color: #890f54;
  /* border: solid;
  border-color: #fdcf00 ; */
  color: #fdcf00;
}


.sofa-button.btn.btn-primary {
  background-color: #890f54;
}
.sofa-button.btn.btn-primary:hover {
  background-color: #fdcf00;
  /* border: solid; */
  /* border-color: #890f54 ; */
  color: #890f54;
}


.act-button.btn.btn-primary {
  background-color: #5ad1db;
}

.projects p {
  font-size: 1.2rem;
}

.previous-experience h1 {
  font-family: "Oswald", sans-serif;
  margin-bottom: 5px;
  margin-top: 30px;
  color: #b06d81;
}

.list-coded li {
  font-size: 1.4rem;
}

li {
  font-size: 1rem;
}

/* Responsive */
@media screen and (min-width: 1025px) {
  .portfolio-section img {
    width: 100%;
    height: 50%;
  }

  h1 {
    display: flex;
    justify-content: left;
    font-size: 2.2rem;
    margin-top: 60px;
    margin-top: 25px;
  }

  .card-text {
    font-size: 1.2rem;
  }
  .projects p {
    font-size: 1.4rem;
  }

  .previous-experience li {
    font-size: 1.4rem;
  }

  .row1 {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 20px;
  }

  /* .card2 {
    width: 28%;
    margin: 0 20px;
  }

  .card3 {
    width: 28%;
    margin-right: 5px;
  } */

  .projects p {
    display: flex;
    justify-content: left;
  }

  .previous-experience {
    display: flex;
    justify-content: left;
    margin-top: 20px;
  }
  .previous-experience1 li {
    margin-left: 20px;
  }
  .previous-experience2 li {
    margin-left: 50px;
  }
}

/* from here new here */
@media screen and (min-width: 985px) and (max-width: 1024px) {
  .portfolio-section img {
    width: 100%;
    height:50%;
  }

  .card {
    width: 44%;
    margin: 0 10px;
    height: 28rem !important;
  }

  .card2 {
    width: 44%;
    margin: 0 10px;
    height: 28rem !important;
  }
  .card3 {
    width: 44%;
    height: 28rem !important;
  }
  
  .card-text {
    font-size: 0.9rem;
  }
  .card-title {
    font-size: 1rem;
  }

  .row1 {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 20px;
  }

  .about-hi-contact h1{
    margin-top: 40px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 984px) {
  .portfolio-section img {
    width: 100%;
    height: 50%;
  }

  .row2 {
    display: flex;
    flex-direction: column;
  }

  .card {
    width: 44%;
    margin-left: 10px;
    height: 25rem !important;
  }

  .card2 {
    width: 44%;
    margin-left: 10px;
    height: 25rem !important;
  }
  .card3 {
    width: 44%;
    margin-right: 20px;
    margin-left: 10px;
    height: 25rem !important;
  }

  .card-text {
    font-size: 0.7rem;
  }
  li {
    font-size:1rem; 
  }
  .card-title {
    font-size: 1.1rem;
  }

  .row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

@media screen and (min-width: 735px) and (max-width: 767px) {
  .row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .portfolio-section img {
    width: 100%;
    height: 50%;
  }

  .card2 {
    margin-top: 10px;
  }

  .card3 {
    margin-top: 10px;
    margin-right: 20px;
  }
 }

@media screen and (min-width: 733px) and (max-width: 734px) {
  .row1{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: center;
  }

  .portfolio-section img {
    width: 100%;
    height: 50%;
  }

  .card {
    width: 61%;
  }

  .card2 {
    width: 61%;
    margin-top: 10px;
  }

  .card3 {
    width: 61%;
    margin-top: 10px;
    margin-right: 20px;
  }

  .cardSofa{
    margin-right: 20px;
  }

}

@media screen and (min-width: 669px) and (max-width: 732px) {

  .row1{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .portfolio-section img {
    height: 50%;
    width: 100%;
  }

.cardSofa{
  margin-right: 20px;
}

.card {
  width: 61%;
}

  .card2 {
    width: 61%;
    margin-top: 10px;
  }

  .card3 {
    width: 61%;
    margin-top: 10px;
    margin-right: 20px;
  }

}

@media screen and (min-width: 649px) and (max-width: 668px) {

  .row1{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .portfolio-section img {
    width: 100%;
    height: 50%;
    }

    .card {
      width: 75%;
    }

  .card2 {
    width: 75%;
    margin-top: 10px;
  }

  .card3 {
    width: 75%;
    margin-top: 10px;
    margin-right: 20px;   
  }

  .cardSofa{
    margin-right: 20px;
  }
}

@media screen and (min-width: 542px) and (max-width: 648px) {
  .portfolio-section img {
    width: 100vh;
    margin-left: 2px;
    margin-top: 3px;
  }

  .card2 {
    border-color: #2c5606;
    border-radius: 20%;
    border-style: solid;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .card3 {
    border-color: #2c5606;
    border-radius: 20%;
    border-style: solid;
    width: 65%;
  }
}

@media screen and (min-width: 540px) and (max-width: 541px) {
      .row1	{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .row2	{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

  .card {
     margin-bottom: 10px;
     width: 100%;
    }
}

@media screen and (min-width: 415px) and (max-width: 539px) {
  .portfolio-section img {
    width: 100vh;
    margin-left: 2px;
    margin-top: 3px;
  }

  .card2 {
    border-color: #2c5606;
    border-radius: 20%;
    border-style: solid;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .card3 {
    border-color: #2c5606;
    border-radius: 20%;
    border-style: solid;
    width: 65%;
  }
}

@media screen and (min-width: 414px) and (max-width: 415px) {
  .portfolio-section img {
    width: 100%;
    height: 50%;
    }

  .card {
    margin-top: 10px;
    width: 100%;
  }

  .card2 {
    margin-top: 10px;
  }

  .card3 {
    margin-top: 10px;
  }

  .row1{
    display: flex;
    flex-direction: column;
  }

  .row2{
    display: flex;
    flex-direction: column;
  }
  .about-hi-contact{
    margin-top: 60px;
  }
}
@media screen and (min-width: 412px) and (max-width:413px) {
  
  .portfolio-section img {
    height: 50%;
    width: 100%;
  }

  .portfolio-section h1 {
    font-size: 3rem;
  }

  .card {
    margin-top: 10px;
    width: 100%;
  }

  .cardSofa{
    height: 24rem !important;
    margin-right: 20px;
  }
  .cardWP{
    height: 31rem !important;
  }

  .about-hi-contact {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .card2 .cardWP{
    height: 18rem !important;
  }

  li{
    margin-right: 40px;
  }
}

@media screen and (min-width: 375px) and (max-width: 411px) {

 
  .portfolio-section img {
    height: 50%;
    width: 100%;
  }

  .portfolio-section h1 {
    font-size: 3rem;
  }

  .card {
    margin-top: 10px;
    width: 100%;
  }

  .cardSofa{
    height: 24rem !important;
    margin-right: 20px;
  }
  .cardWP{
    height: 31rem !important;
  }

  .about-hi-contact {
    margin-top: 40px;
    margin-bottom: 20px;
  }

 
  .row1{
    margin-left:4px;
  }

  .row2{
    margin-left:4px;
  }

  .card2 .cardWP{
    height: 18rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .about-hi-portfolio h1 {
    font-size: 2.4rem;
  
  }
  .list-coded li {
    font-size: 1.2rem;
}
.card {
  margin-bottom: 10px;
  width: 100%;
}

}

@media screen and (min-width: 200px) and (max-width: 319px) {
  .portfolio-section h1 {
    font-size: 1.6rem;
  }

  .card{
    width: 100%;
    height: 28rem;
    margin-bottom: 10px;
  }

  .card2 {
    margin-top: 10px;
  }

  .card3 {
    margin-top: 10px;
    height: 32rem !important;
  }

}

